import React from 'react';
import Speaker from '../../images/microphone2.svg';
import Books from '../../images/booksaved.svg';
import Culture from '../../images/musicdashboard.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]); // add Navigation and Pagination modules

export default function MobileGroups() {
  const { t } = useTranslation('groups');

  return (
    <div className="flex flex-col items-center justify-start w-full mt-12">
      <h3 className="text-center text-2xl font-bold mb-4">{t('main_groups_h')}</h3>
      <p className="text-center w-[80vw] text-sm text-[#9ca3af]">{t('main_groups_description')}</p>
      <div className="flex flex-wrap justify-center w-[80vw] mt-6 py-4 rounded-xl">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides={true} autoplay={{
            "delay": 3500,
            "disableOnInteraction": false,
          }}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="flex items-center justify-between flex-col w-[100%] py-8 px-6 bg-white shadow-md border border-slate-200 rounded-xl hover:shadow-xl hover:cursor-pointer">
              <h3 className="font-bold text-2xl mb-2">{t('blabbers_h')}</h3>
              <p className="text-center text-sm font-light text-slate-400 w-[90%]">{t('blabbers_description')}</p>
              <img className="mt-4" src={Speaker} alt="Speaker" />
              {/* <p className='text-center text-sm'>{t('blabbers_text')}</p> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex items-center justify-between flex-col w-[100%] py-8 px-6 bg-white shadow-md border border-slate-200 rounded-xl hover:shadow-xl hover:cursor-pointer">
              <h3 className="font-bold mb-2 text-2xl">{t('book_club_h')}</h3>
              <p className="text-center text-sm font-light text-slate-400 w-[90%]">{t('book_club_description')}</p>
              <img className="mt-4" src={Books} alt="Book club" />
              {/* <p className='text-center text-sm'>{t('book_club_text')}</p> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex items-center justify-between flex-col w-[100%] py-8 px-6 bg-white shadow-md border border-slate-200 rounded-xl hover:shadow-xl hover:cursor-pointer">
                <h3 className='font-bold mb-2 text-2xl'>{t('culture_events_h')}</h3>
                <p className='text-center text-sm font-light text-slate-400 w-[90%] '>{t('culture_events_description')}</p>
                <img className='mt-4' src={Culture} alt="Culture events" />
                {/* <p className='text-center text-sm'>{t('culture_events_text')}</p> */}
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
    </div>
  )
}
