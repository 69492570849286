import React from 'react'
import Hero from './Hero'
import Partner from './Partner'
import About from './About'
import Start from './Start'
import Groups from './Groups'
import Help from './Help'
import Footer from '../../components/Footer'
import MobileHomepage from '../MobileHomepage/MobileHomepage'
// import { FaArrowUp } from "react-icons/fa";



export default function Homepage() {
  return (
   <>
    <div className='hidden relative lg:flex md:flex-col w-full items-center justify-center'>
      <Hero />
      <Partner />
      <About />
      <Groups />
      <Start />
      <Help />
    </div>
    <MobileHomepage />
    <Footer/>
    {/* <div className="hidden lg:fixed bottom-12 right-24 rounded-xl bg-[#7A66E9] first-letter: h-12 w-12 items-center justify-center lg:flex text-white text-xl font-bold shadow-xl hover:shadow-2xl hover:opacity-95 hover:cursor-pointer transition-all ease-in-out delay-300"><a href='#hero'><FaArrowUp /></a></div> */}
   </>
  )
}
