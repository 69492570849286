import React from 'react'
import { Link } from 'react-router-dom'
import Download from '../../images/Icon_download.png'
import Register from '../../images/Icon_register.png'
import Connect from '../../images/Icon_connect.png'
import { useTranslation } from 'react-i18next';


export default function Start() {
const { t } = useTranslation('start');

  return (
    <div id='m-start' className="flex flex-col items-center justify-center h-auto w-full mt-16">
        <div className="flex flex-col justify-start items-center w-full">
            <h2 className='text-center text-2xl font-bold'>{t('start_h')}</h2>
            <p className='text-center px-8 my-4 text-sm text-[#9ca3af] sm:max-w-[60vw] '>{t('start_description')}</p>
        </div>
        <div className="flex flex-col justify-center">
            <div className="flex flex-col h-full w-[80vw] sm:w-[70vw] m-4 bg-white border-solid border border-[#9ca3af] border-opacity-30 rounded-3xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start text-[#8775EB] items-end">
                        <p className='font-black text-xl mr-2'>1.</p>
                        <h2 className='text-left text-xl font-bold'>{t('download_h')}</h2>
                    </div>
                    <img className='ml-6 w-[40px]' src={Download} alt="Download" />
                </div>
                <p className='text-left text-base ml-2 mt-6'>{t('download_description')}</p>
                <div className="flex pl-2">
                    <Link to='https://discord.com/download' target="blank" className='font-bold text-sm text-black hover:text-[#8775EB] duration-150 underline underline-offset-4 mt-6'>{t('discord_download_link')}</Link>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex flex-col h-[180px] w-[80vw] sm:w-[70vw] m-5 bg-white border-solid border border-[#9ca3af] border-opacity-30 rounded-3xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start items-end text-[#8775EB]">
                        <p className='font-black text-xl mr-2'>2</p>
                        <h2 className='text-left text-xl font-bold'>{t('register_h')}</h2>
                    </div>
                    <img className='ml-6 w-[40px]' src={Register} alt="Download" />
                </div>
                <p className='text-sm mt-3'>{t('register_description')}</p>
                </div>
                <div className="flex flex-col h-[250px] w-[80vw] sm:w-[70vw] m-5 bg-white border-solid border border-[#9ca3af] border-opacity-30 rounded-3xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start items-end text-[#8775EB]">
                        <p className='font-black text-xl mr-2'>3</p>
                        <h2 className='text-left text-xl font-bold'>{t('connect_h')}</h2>
                    </div>
                    <img className='ml-6 w-[40px]' src={Connect} alt="Download" />
                </div>
                <p className='text-sm mt-3'>{t('connect_description')}</p>
                <Link to='https://discord.gg/eB9SF2J9jq' target="blank" className='font-semibold text-white text-sm bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 rounded-full px-4 py-2 mt-6'>{t('connect')}</Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}
