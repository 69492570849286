import React from 'react'

export default function Admin() {
  return (
    <div className="flex flex-col justify-center items-center w-[60vw] h-[70vh] bg-slate-200">
        <div className=""></div>
        <div className=""></div>
    </div>
  )
}
