import React from 'react'
import MobileHero from '../MobileHomepage/MobileHero'
import MobileAbout from '../MobileHomepage/MobileAbout'
import MobileStart from '../MobileHomepage/MobileStart'
import MobileHelp from './MobileHelp'
import MobilePartner from './MobilePartner'
import MobileGroups from './MobileGroups'


export default function MobileHomepage() {
  return (
    <div className='flex lg:hidden w-full flex-col items-center justify-center mt-[20px]'>
      <MobileHero />
      <MobileAbout />
      <MobilePartner />
      <MobileGroups />
      <MobileStart />
      <MobileHelp />
    </div>
  )
}
