import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import Phone from '../../images/call.png'
import Adress from '../../images/location.png'
import Sms from '../../images/sms.png'

import { useTranslation } from 'react-i18next';


export default function Contacts() {

const { t } = useTranslation('contacts');

const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

emailjs.sendForm('service_dl5c6m7', 'template_pe2ui2i', form.current, 'IAf3etytOmfZmoBns')
  .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
};

function validateEmail(e) {
  // Get form inputs and checkbox
  const nameInput = e.target.elements.name;
  const emailInput = e.target.elements.email;
  const messageInput = e.target.elements.message;
  const checkbox = e.target.elements.checkbox;

  // Validate name input
  if (nameInput.value.trim() === '') {
    nameInput.nextElementSibling.textContent = 'Please enter your name';
    nameInput.nextElementSibling.classList.add('text-red-500');
  } else {
    nameInput.nextElementSibling.textContent = '';
    nameInput.nextElementSibling.classList.remove('text-red-500');
  }

  // Validate email input
  if (emailInput.value.trim() === '') {
    emailInput.nextElementSibling.textContent = 'Please enter your email';
    emailInput.nextElementSibling.classList.add('text-red-500');
  } else if (!/\S+@\S+\.\S+/.test(emailInput.value)) {
    emailInput.nextElementSibling.textContent = 'Please enter a valid email';
    emailInput.nextElementSibling.classList.add('text-red-500');
  } else {
    emailInput.nextElementSibling.textContent = '';
    emailInput.nextElementSibling.classList.remove('text-red-500');
  }

  // Validate message input
  if (messageInput.value.trim() === '') {
    messageInput.nextElementSibling.textContent = 'Please enter a message';
    messageInput.nextElementSibling.classList.add('text-red-500');
  } else {
    messageInput.nextElementSibling.textContent = '';
    messageInput.nextElementSibling.classList.remove('text-red-500');
  }

  // Validate checkbox
  if (!checkbox.checked) {
    checkbox.nextElementSibling.textContent = 'Please agree to the terms of service';
    checkbox.nextElementSibling.classList.add('text-red-500');
  } else {
    checkbox.nextElementSibling.textContent = '';
    checkbox.nextElementSibling.classList.remove('text-red-500');
  }

  // Check if all inputs are valid
  const isValid = !nameInput.nextElementSibling.textContent &&
    !emailInput.nextElementSibling.textContent &&
    !messageInput.nextElementSibling.textContent &&
    !checkbox.nextElementSibling.textContent;

  // Enable/disable submit button
  const submitButton = e.target.elements.submit;
  submitButton.disabled = !isValid;
}


return (
  <div className="flex flex-col justify-center items-center lg:h-screen w-full lg:mt-[30px] bg-[#FCFDFF]">
    <div className='flex flex-col lg:flex-row w-[96wv] lg:w-auto md:p-4 mt-24 lg:mt-24 mb-16'>
      <form ref={form} onSubmit={sendEmail} onChange={validateEmail} className="flex h-full flex-col justify-center items-center lg:items-start lg:w-[60%] lg:pr-8 xl:pr-24 mb-16 lg:mb-0">
        <h2 className='text-xl lg:text-3xl font-bold'>{t('contacts_h')}</h2>
        <p className='text-gray-500 mt-4 text-lg px-8 md:px-0 text-center md:text-left'>{t('contacts_description')}</p>
        <div className="flex flex-col lg:flex-row w-[90vw] lg:w-full mt-6 md:mt-16">
          <div className="flex md:grow flex-col items-start lg:mr-6">
            <label className='font-semibold' htmlFor="">{t('name')}</label>
            <input className='border text-sm border-slate-200 mt-2 px-4 py-2 lg:px-2 lg:py-2 rounded-md w-[90vw] lg:w-full' placeholder={t('input_name')} type="text" name="name" />
          </div>
          <div className="flex md:grow flex-col items-start mt-4 lg:mt-0">
            <label className='font-semibold' htmlFor="">{t('email')}</label>
            <input className='border text-sm border-slate-200 mt-2 px-4 py-2 lg:px-2 lg:py-2 rounded-md w-[90vw] lg:w-full' placeholder={t('input_email')} type="text" name="email" />
          </div>
        </div>
        <div className="flex lg:grow w-[90vw] lg:w-full flex-col items-start lg:mr-12 mt-6">
          <label className='font-semibold' htmlFor="">{t('message')}</label>
          <input className='border text-sm border-slate-200 mt-2 px-4 lg:px-8 lg:mr-12 h-[100px] lg:h-[134px] w-[90vw] lg:w-full rounded-md' placeholder={t('input_message')} type="text" name="message" />
        </div>
        <div className="flex items-center justify-center lg:justify-start mt-6 md:mt-4 w-[90vw] lg:w-full">
          <input className='mt-4 default:border-slate-200' type="checkbox" />
          <p className='mt-4 ml-4 text-sm'>{t('terms')} <a className='text-[#7A66E9] hover:text-[#8775EB] duration-150 underline underline-offset-2' href='/'>{t('terms_link')}</a></p>
        </div>
        <button type="submit" value="Send" className='py-3 w-[34vw] md:w-[20vw] lg:w-[5vw] bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 rounded-full text-white mt-6 text-sm font-semibold'>{t('send')}</button>   
      </form>
            <div className="flex flex-col h-full w-auto items-start justify-between">

            <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start h-full w-full lg:w-full text-center md:text-left mb-12 lg:mb-0">
                <h2 className='text-xl lg:text-3xl font-bold mt-4 lg:mt-0'>{t('information')}</h2>
                <p className='text-gray-500 mt-4 text-lg lg:mt-4'>{t('information_description')}</p>
            <div className="flex flex-col md:justify-center lg:justify-center md:items-center lg:items-start w-[90vw] pl-2 lg:pl-0 lg:w-[340px] border bg-white border-slate-200 rounded-xl py-6 mt-8 lg:mt-24">
               <div className="flex flex-row items-center lg:ml-6 mb-4">
                    <img className="h-[40%] md:h-[60%] mx-4 mb-2 pr-2 lg:pr-0" src={Phone} alt="Phone" />
                    <div className="flex flex-col mb-2">
                        <p className='font-semibold'>{t('phone')}</p>
                        <p className='text-slate-400 font-light text-xs'>{t('phone_info')}</p>
                    </div>
               </div>
               <div className="flex flex-row items-center lg:ml-6 mb-4">
                    <img className="h-[40%] lg:h-[60%] mx-4 mb-2 pr-2 lg:pr-0" src={Adress} alt="Adress" />
                    <div className="flex flex-col mb-2">
                        <p className='font-semibold'>{t('adress')}</p>
                        <p className='text-slate-400 font-light text-xs'>{t('adress_info')}</p>
                    </div>
               </div>
                <div className="flex flex-row items-center lg:ml-6">
                    <img className="h-[40%] lg:h-[60%] mx-4 mb-2 pr-2 lg:pr-0" src={Sms} alt="Sms" />
                    <div className="flex flex-col mb-2">
                        <p className='font-semibold'>{t('email')}</p>
                        <p className='text-slate-400 font-light text-xs'>{t('email_info')}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
