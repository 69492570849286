import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


export default function MobileHelp() {

const { t } = useTranslation('help');

  return (
    <div className='flex flex-col items-center justify-start h-auto mt-12 mb-24'>
    <div className="flex flex-col items-start justify-start w-[80vw] sm:w-[70vw]">
        <h3 className='text-left text-2xl font-bold mb-4'>{t('help_h')}</h3>
        <p className='text-left text-sm mb-4'>{t('help_description_1')}</p>
        <p className='text-xs text-left'>{t('help_description_2')}</p>
    </div>
    <div className="flex flex-col items-center mt-8">
        <div className="flex flex-col items-center">
            <div className="flex flex-col items-start justify-center p-8 text-left w-[80vw] sm:w-[70vw] h-[180px] bg-black text-white rounded-3xl">
                <h4 className='text-left font-bold text-xl'>{t('blog_h')}</h4>
                <p className='text-left text-xs my-4'>{t('blog_description')}</p>
                <Link to='/blog' className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('blog_link')}</Link>
            </div>
            <div className="flex flex-col items-start justify-center mt-4 p-8 text-left w-[80vw] sm:w-[70vw] h-[180px] bg-[#7A66E9] text-white rounded-3xl">
                <h4 className='text-left font-bold text-xl'>{t('contactus_h')}</h4>
                <p className='text-left text-xs my-4'>{t('contactus_description')}</p>
                <Link to="/contacts" className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('contactus_link')}</Link>
            </div>
        </div>
        <div className="flex flex-row items-start justify-center">
            <div className="flex flex-col items-start justify-center p-8 text-left w-[80vw] sm:w-[70vw] h-[180px] bg-[#5B5B5B] mt-4 text-white rounded-3xl">
                <h4 className='text-left font-bold text-xl'>{t('faq_h')}</h4>
                <p className='text-left text-xs my-4'>{t('faq_description')}</p>
                <Link to='/faq' className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('faq_link')}</Link>
            </div>
        </div>
    </div>
</div>
  )
}
