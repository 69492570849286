import React from 'react';
import Accordion from './Accordion';
import { useTranslation } from 'react-i18next';

export default function Faq() {

  const { t } = useTranslation('faq');
  const items = t('items', { returnObjects: true });

  return (
    <div className="flex flex-col justify-start items-center mt-24 lg:mt-8 w-full min-h-[100vh] bg-[#FCFDFF]">
      <div className="flex flex-col justify-center items-center md:mt-24">
        <h2 className='text-center text-lg lg:text-4xl font-bold mt-4'>{t('faq_h')}</h2>
        <p className='text-center text-xs lg:text-base font-normal my-2 md:my-6 w-[60vw] sm:w-[40vw] md:w-[30vw] xl:w-[28vw]'>{t('faq_description')}</p>
        <a className='mb-8 mt-6 md:mt-4 font-bold underline underline-offset-2 text-[#7A66E9] hover:text-[#8775EB] duration-150' href="/contacts">{t('faq_link')}</a>
      </div>
      <Accordion items={items} />
    </div>
  );
}
