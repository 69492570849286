import React from 'react'
import { Link } from 'react-router-dom'
import Download from '../../images/Icon_download.png'
import Register from '../../images/Icon_register.png'
import Connect from '../../images/Icon_connect.png'
import QR from '../../images//QR.png'
import { useTranslation } from 'react-i18next';


export default function Start() {

const { t } = useTranslation('start');

  return (
    <div id="start" className="flex flex-col items-center justify-start h-auto w-full pt-16 xl:py-20 bg-[#FCFDFF]">
        <div className="flex flex-col">
            <h2 className='text-center text-4xl font-bold mb-4'>{t('start_h')}</h2>
            <p className='text-center md:w-[50vw] xl:w-[30vw] xl:text-lg text-[#9ca3af]'>{t('start_description')}</p>
        </div>
        <div className="flex flex-row flex-wrap mt-4 justify-center">
            <div className="flex flex-col lg:w-[70vw] xL:w-1/3 min-w-[320px] max-w-[400px] m-6 bg-white border-solid border border-[#9ca3af] border-opacity-30 rounded-3xl shadow-xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start items-end">
                        <p className='font-black text-2xl mr-2'>1.</p>
                        <h2 className='text-left text-2xl font-bold'>{t('download_h')}</h2>
                    </div>
                    <img className='ml-6 w-[60px]' src={Download} alt="Download" />
                </div>
                <p className='text-left text-base ml-2 mt-6'>{t('download_description')}</p>
                <div className="flex flex-row items-center mt-6">
                    <img className='w-[140px] h-[140px]' src={QR} alt="QR-code" />
                    <p className='text-left text-lg ml-4'>{t('scan_description_1')}<span className='font-bold'> {t('scan_discord')} </span>{t('scan_description_2')}</p>
                </div>
                <div className="flex items-start justify-start">
                    <Link to='https://discord.com/download' target="blank" className='font-bold text-[#7A66E9] hover:text-[#8775EB] duration-150 underline underline-offset-4 mt-6'>{t('discord_download_link')}</Link>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="flex flex-col h-[180px] xL:w-1/3 min-w-[320px] max-w-[400px] m-5 bg-white border-solid border border-[#9ca3af] border-opacity-30 shadow-xl rounded-3xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start items-end">
                        <p className='font-black text-2xl mr-2'>2.</p>
                        <h2 className='text-left text-2xl font-bold'>{t('register_h')}</h2>
                    </div>
                    <img className='ml-6 w-[60px]' src={Register} alt="Download" />
                </div>
                <p className='text-sm mt-3'>{t('register_description')}</p>
                </div>
                <div className="flex flex-col h-[220px] xL:w-1/3 min-w-[320px] max-w-[400px] m-5 bg-white border-solid border border-[#9ca3af] border-opacity-30 shadow-xl rounded-3xl items-start justify-start p-8">
                <div className="flex justify-between items-end w-full">
                    <div className="flex justify-start items-end">
                        <p className='font-black text-2xl mr-2'>3.</p>
                        <h2 className='text-left text-2xl font-bold'>{t('connect_h')}</h2>
                    </div>
                    <img className='ml-6 w-[60px]' src={Connect} alt="Download" />
                </div>
                <p className='text-sm mt-3'>{t('connect_description')}</p>
                <Link to='https://discord.gg/eB9SF2J9jq' target="blank" className='font-semibold text-white text-sm bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 rounded-full px-4 py-2 mt-4'>{t('connect')}</Link>
                </div>
                
            </div>
        </div>
    </div>
  )
}
