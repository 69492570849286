import React from 'react'
import { Link } from 'react-router-dom'

export default function UnderConstruction() {
  return (
    <div className='flex flex-col h-[88vh] w-full justify-center items-center mb-8'>
        <p className='flex font-black text-black text-5xl'>This page is Under Construction</p>
        <Link className='font-semibold text-[#7A66E9] underline underline-offset-2 mt-6' to='/blog'>Back to start</Link>
    </div>
  )
}
