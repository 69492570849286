import React, { useState } from 'react';

export default function Accordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [showAll, setShowAll] = useState(false);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="accordion flex flex-col mb-24 lg:mb-12 items-center justify-center z-10">
      {showAll
        ? items.map((item, index) => (
            <div key={index} className="mb-4">
              <button
                onClick={() => handleClick(index)}
                className="flex w-[92vw] md:w-[60vw] xl:w-[30vw] items-center text-left text-sm md:text-lg font-medium py-4 px-6 border rounded-md border-gray-300"
              >
                <span className="flex w-[92vw] md:w-[60vw] xl:w-[30vw]">{item.title}</span>
                <span className="ml-2">
                  {activeIndex === index ? '-' : '+'}
                </span>
              </button>
              {activeIndex === index && (
                <div className="p-6 mt-2 w-[92vw] md:w-[60vw] xl:w-[30vw] bg-gray-100">
                  {item.content}
                </div>
              )}
            </div>
          ))
        : items.slice(0, 3).map((item, index) => (
            <div key={index} className="mb-4">
              <button
                onClick={() => handleClick(index)}
                className="flex w-[92vw] md:w-[60vw] xl:w-[30vw] items-center text-left text-sm md:text-lg font-medium py-4 px-6 border rounded-md border-gray-300"
              >
                <span className="flex w-[92vw] md:w-[60vw] xl:w-[30vw]">{item.title}</span>
                <span className="ml-2">
                  {activeIndex === index ? '-' : '+'}
                </span>
              </button>
              {activeIndex === index && (
                <div className="w-[92vw] md:w-[60vw] xl:w-[30vw] p-6 mt-2 bg-gray-100">
                  {item.content}
                </div>
              )}
            </div>
          ))}
      {items.length > 3 && (
        <button onClick={toggleShowAll} className="px-10 py-3 mt-6 bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 rounded-full text-white mr-4 text-sm font-semibold">
          {showAll ? 'Back' : 'More'}
        </button>
      )}
    </div>
  );
}
