import React from 'react'
import {Link} from 'react-router-dom'
import BlogImage from '../../images/devicemessage.png'
import ContactsImage from '../../images/messages2.png'
import FaqImage from '../../images/messagequestion.png'
import { useTranslation } from 'react-i18next';


export default function Help() {

const { t } = useTranslation('help');

  return (
    <div className='flex md:flex-col xl:flex-row items-center justify-start h-auto mt-16 xl:mt-24 mb-36'>
        <div className="flex flex-col md:items-center xl:items-start justify-start md:w-[70vw] xl:w-[35vw] xl:mr-16 md:mb-12 xl:mb-0">
            <h3 className='text-center text-4xl font-bold mb-4'>{t('help_h')}</h3>
            <p className='text-center xl:text-left md:w-[50vw] xl:w-[30vw] xl:text-xl my-2 text-[#9ca3af]'>{t('help_description_1')}</p>
            <p className='md:hidden xl:flex text-xl font-light text-center mt-4 xl:text-left'>{t('help_description_2')}</p>
        </div>
        <div className="flex flex-col items-center">
            <div className="flex flex-row items-end">
                <div className="flex flex-col items-start justify-center p-8 text-left w-[230px] h-[200px] bg-black text-white rounded-3xl">
                    <div className="flex w-full items-end justify-between">
                        <h4 className='text-left font-bold text-xl'>{t('blog_h')}</h4>
                        <img className='w-[42px]' src={BlogImage} alt="Blog" />
                    </div>
                    <p className='text-left text-xs my-4'>{t('blog_description')}</p>
                    <Link to='/blog' className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('blog_link')}</Link>
                </div>
                <div className="flex flex-col items-start justify-center p-8 text-left w-[260px] h-[240px] bg-[#7A66E9] ml-6 text-white rounded-3xl">
                    <div className="flex w-full items-end justify-between">
                        <h4 className='text-left font-bold text-xl'>{t('contactus_h')}</h4>
                        <img className='w-[42px]' src={ContactsImage} alt="Contact us" />
                    </div>
                    <p className='text-left text-xs my-4'>{t('contactus_description')}</p>
                    <Link to='/contacts' className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('contactus_link')}</Link>
                </div>
            </div>
            <div className="flex flex-row items-start justify-center">
                <div className="flex flex-col items-start justify-center p-8 text-left w-[250px] h-[180px] bg-[#5B5B5B] ml-6 mt-4 text-white rounded-3xl">
                    <div className="flex w-full items-end justify-between">
                        <h4 className='text-left font-bold text-xl'>{t('faq_h')}</h4>
                        <img className='w-[42px]' src={FaqImage} alt="FAQ" />
                    </div>
                    <p className='text-left text-xs my-4'>{t('faq_description')}</p>
                    <Link to='/faq' className="text-left underline underline-offset-2 hover:text-white/90 hover:font-medium hover:duration-200 transition-all ease-in">{t('faq_link')}</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
