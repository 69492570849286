import React from 'react'
import Speaker from '../../images/microphone2.svg'
import Books from '../../images/booksaved.svg'
import Culture from '../../images/musicdashboard.svg'
import { useTranslation } from 'react-i18next';


export default function Groups() {

const { t } = useTranslation('groups');

  return (
    <div className='flex flex-col items-center justify-start w-full'>
        <h3 className='text-center text-4xl font-bold mb-4'>{t('main_groups_h')}</h3>
        <p className='text-center md:w-[50vw] xl:w-[30vw] xl:text-lg text-[#9ca3af]'>{t('main_groups_description')}</p>
        <div className="flex flex-wrap justify-center w-[96vw] px-16 py-4 rounded-xl">
            <div className="flex items-center justify-between flex-col lg:w-[70vw] xL:w-1/3 min-w-[250px] max-w-[400px] min-h-[30vh] m-4 p-8 bg-white border-solid border border-[#9ca3af] border-opacity-30 shadow-xl rounded-3xl hover:cursor-pointer">
                <h3 className='font-bold text-2xl mb-2'>{t('blabbers_h')}</h3>
                <p className='text-center text-sm font-light text-slate-400 w-[60%]'>{t('blabbers_description')}</p>
                <img className='mt-4' src={Speaker} alt="Speaker" />
                {/* <p className='text-center text-sm'>{t('blabbers_text')}</p> */}
            </div>
            <div className="flex items-center justify-between flex-col lg:w-[70vw] xL:w-1/3 min-w-[250px] max-w-[400px] min-h-[30vh] m-4 p-8 bg-white border-solid border border-[#9ca3af] border-opacity-30 shadow-xl rounded-3xl hover:cursor-pointer">
                <h3 className='font-bold mb-2 text-2xl'>{t('book_club_h')}</h3>
                <p className='text-center text-sm font-light text-slate-400 w-[60%]'>{t('book_club_description')}</p>
                <img className='mt-4' src={Books} alt="Book club" />
                {/* <p className='text-center text-sm'>{t('book_club_text')}</p> */}
            </div>
            <div className="flex items-center justify-between flex-col lg:w-[70vw] xL:w-1/3 min-w-[250px] max-w-[400px] min-h-[30vh] m-4 p-8 bg-white border-solid border border-[#9ca3af] border-opacity-30 shadow-xl rounded-3xl hover:cursor-pointer">
                <h3 className='font-bold mb-2 text-2xl'>{t('culture_events_h')}</h3>
                <p className='text-center text-sm font-light text-slate-400 w-[60%] '>{t('culture_events_description')}</p>
                <img className='mt-4' src={Culture} alt="Culture events" />
                {/* <p className='text-center text-sm'>{t('culture_events_text')}</p> */}
            </div>
        </div>
    </div>
  )
}
