import React from 'react'
import { Link } from 'react-router-dom'

export default function MainPost() {
  return (
    <div className='flex flex-col md:flex-row h-full justify-between w-[90vw] max-w-[400px] md:max-w-none md:w-[70vw] md:h-[400px] hover:shadow-xl hover:cursor-pointer border border-slate-200 rounded-2xl xl:mb-8'>
        <div className="flex justify-center bg-blog-main bg-cover bg-center w-[90vw] max-w-[400px] h-[200px] md:h-auto md:w-[90vw] md:max-w-none rounded-t-2xl md:rounded-tr-none md:rounded-l-2xl"></div>
        <div className="flex flex-col w-[90vw] max-w-[400px] md:max-w-none my-8 md:w-full justify-center px-8">
          <div>
            <h2 className='text-xl font-black hover:text-[#7A66E9] hover:cursor-pointer'>The Four Strands of Language Learning</h2>
            <p className='mt-2 font-light text-xs lg:text-base'>by Paul Nation</p>
          </div>
          <div className="mt-4 lg:mt-8 text-xs md:text-sm">
            <p>Discover Paul Nation's four strands of language learning and how they can help you improve your language skills.</p>
          </div>
            <Link to="/blog/main" className='bg-[#7A66E9] text-white w-[100px] lg:w-[120px] py-2 px-4 text-center text-xs lg:text-sm rounded-xl mt-6 lg:mt-8'>Read more</Link>
        </div>
    </div>
  )
}
