import React from 'react'
import One from '../../images/one.png'
import Two from '../../images/two.png'
import DoneIcon from '../../images/tickcircle.png'
import { useTranslation } from 'react-i18next';


export default function About() {

const { t } = useTranslation('about');

  return (
    <div id='about' className='flex flex-row items-center justify-center w-[80%] h-auto mt-16 mb-12 xl:mb-24'>
        <div className="flex flex-row lg:flex-nowrap items-center md:justify-center lg:justify-start">
            <img className='md:hidden lg:flex lg:max-h-[35vh] xl:max-h-[45vh] 2xl:max-h-[55vh] w-full md:w-auto lg:w-full m-4' src={Two} alt="Two" />
            <img className='lg:max-h-[41vh] xl:max-h-[51vh] 2xl:max-h-[61vh] w-full md:w-auto lg:w-full m-4' src={One} alt="One" />
        </div>
        <div className="flex flex-col ml-16 md:ml-16">
            <h2 className='text-3xl text-left mb-4'>{t('about_h')} <span className='text-[#7A66E9]'>{t('blabber_h')}</span></h2>
            <p className='text-left w-[30vw] md:w-[38vw] lg:w-[30vw] mb-6 text-xs xl:text-base text-gray-500'>{t('about_description')}</p>
                <div className="flex flex-col items-start justify-start w-[30vw] md:w-[38vw] lg:w-[30vw] mb-2">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('language_club')}</h4>
                    </div>
                    <p className='text-left text-xs xl:text-sm'>{t('language_club_description')}</p>
                </div>
                <div className="flex flex-col items-start justify-start w-[30vw] md:w-[38vw] lg:w-[30vw] mb-2">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('own_channels')}</h4>
                    </div>
                    <p className='text-left text-xs xl:text-sm'>{t('own_channels_description')}</p>
                </div>
                <div className="flex flex-col items-start justify-start w-[30vw] md:w-[38vw] lg:w-[30vw] mb-2">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('teachers_channels')}</h4>
                    </div>
                    <p className='text-left text-xs xl:text-sm'>{t('teachers_channels_description')}</p>
                </div>
        </div>
    </div>
  )
}
