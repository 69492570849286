import React from 'react'
import {Link} from 'react-router-dom'
import Subscribe from '../pages/Homepage/Subscribe'
import { useTranslation } from 'react-i18next';


export default function Footer() {

const { t } = useTranslation('footer');


  return (
    <div className='flex flex-col items-center justify-center bg-[#252525] py-12 md:h-[38vh] w-full mb-[70px] lg:mb-0'>
        <div className="hidden lg:flex flex-col justify-center items-start">
            <div className="text-white md:pl-6 lg:pl-16 font-thin text-2xl quicksand">blabber</div>
            <div className="flex flex-row">
                <div className="flex flex-col items-start justify-start pt-2">
                    <h3 className='text-white font-semibold md:pl-6 lg:pl-16 text-base mb-2'>{t('menu')}</h3>
                    <Link to='/' className='text-white md:pl-6 lg:pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('home')}</Link>
                    <Link to='/faq' className='text-white md:pl-6 lg:pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('rules')}</Link>
                    <Link to='/blog' className='text-white md:pl-6 lg:pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('blog')}</Link>
                    <Link to='/contacts' className='text-white md:pl-6 lg:pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('contacts_footer')}</Link>
                </div>
                <div className="flex flex-col items-start justify-start pt-2">
                    <h3 className='text-white font-semibold pl-16 text-base mb-2'>{t('social_media')}</h3>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('facebook')}</Link>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('instagram')}</Link>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('linkedin')}</Link>
                </div>
                <div className="flex flex-col items-start justify-start pt-2">
                    <h3 className='text-white font-semibold pl-16 text-base mb-2'>{t('adress')}</h3>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('street')}</Link>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('city')}</Link>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('post')}</Link>
                </div>
                <div className="flex flex-col items-start justify-start pt-2">
                    <h3 className='text-white font-semibold pl-16 text-base mb-2'>{t('contacts')}</h3>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('phone')}</Link>
                    <Link className='text-white pl-16 font-normal text-sm lg:text-xs xl:text-sm'>{t('email')}</Link>
                </div>
                <Subscribe />
            </div>
        </div>
        <div className="flex flex-col lg:hidden">
            <Subscribe />
        </div>
    </div>
  )
}
