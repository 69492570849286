import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { useTranslation } from 'react-i18next';


export default function Subscribe() {

  const { t } = useTranslation('subscribe');


  const form = useRef();
  const [isSubscribed, setIsSubscribed] = useState(false);

  const validateEmail = () => {
    const emailInput = form.current.email;
    const isValid = emailInput.checkValidity();

    if (!isValid) {
      alert('Please enter a valid email address');
    }

    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const isValid = validateEmail();

    if (isValid) {
      emailjs
        .sendForm('service_ug7h2k7', 'template_k8bzh4l11', form.current, 'bOMh1JHcZXN7MADBq')
        .then(
          (result) => {
            console.log(result.text);
            form.current.reset(); // reset the form after submission
            setIsSubscribed(true); // show the success message
            setTimeout(() => setIsSubscribed(false), 3000); // hide the success message after 5 seconds
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-end h-auto pt-4 lg:ml-8">
      <form ref={form} onSubmit={sendEmail}>
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row flex-wrap lg:flex-nowrap items-center justify-center">
            <input
              className="border-solid border border-[#9ca3af] border-opacity-30 rounded-md min-w-[250px] w-[90vw] mx-4 sm:w-[25vw] h-[50px] pl-8"
              type="email"
              placeholder={t('input')}
              name="email"
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
            />
            <button
              className="flex items-center justify-center bg-[#7A66E9] hover:bg-[#8775EB] duration-150 px-9 mx-2 rounded-md h-[50px] lg:w-[10vw] text-base sm:text-sm lg:text-sm text-white font-bold mt-4 md:mt-0"
              type="submit"
              value="Send"
            >
              {t('subscribe_button')}
            </button>
          </div>
          {isSubscribed && (
            <div className="flex justify-end my-2">
              <p className="flex text-green-600 text-xs">{t('terms')}</p>
            </div>
          )}
        </div>
      </form>
      <p className="text-xs md:text-sm lg:text-xs xl:text-sm text-center text-white mt-6 px-10">
        * {t('subscribe_terms_1')}
        <br />
        {t('subscribe_terms_2')}{' '}
        <span className="font-light tracking-wider font-quicksand text-white">blabber</span>
      </p>
    </div>
  );
}
