import React from 'react'
import MainImage from '../../images/blabber_main.jpg'
import Heart from '../../images/heart.png'
import Donut from '../../images/pattern_donut.png'
import { useTranslation } from 'react-i18next';


export default function Hero() {

  const { t } = useTranslation('hero');

  return (
    <div id='hero' className='flex bg-hero-pattern bg-cover bg-center flex-col h-[calc(100vh-70px)] w-full items-center justify-center mt-[70px]'>
      <div className='absolute z-0 top-0 right-0'>
          <img src={Donut} alt="Donut"/>
      </div>
      <div className="flex flex-row items-center justify-around w-[84vw] mb-[6vh] mt-12">
        <div className="flex flex-col items-start justify-start">
          <span className='text-left text-[#9ca3af] text-[12px] font-medium mb-6 xl:mb-12'>{t('slogan')}</span>
          <h1 className="text-left md:text-3xl xl:text-5xl font-extrabold tracking-wide mb-6 xl:mb-8">{t('hero_black_1')}<br></br> {t('hero_black_2')} <br></br><span className='text-[#7A66E9]'>{t('hero_black_3')}</span></h1>
          <p className='text-left xl:text-xl text-[#9ca3af] md:w-[33vw]'>{t('hero_description')}</p>
          <div className="flex flex-row mt-12">
              <a href='#start'>
                <button className='px-6 py-3 bg-[#7A66E9] hover:bg-[#8775EB] hover:shadow-slate-400 duration-150 shadow-lg shadow-[#7A66E9] rounded-full text-white mr-4 text-sm font-semibold'>{t('connect')}</button>
              </a>
              <a href='#about'>
                <button className='bg-white px-6 py-3 shadow-lg shadow-slate-400 rounded-full text-[#7A66E9] hover:text-[#8775EB] hover:shadow-[#8775EB] duration-150 mr-4 text-sm font-semibold'>{t('discover')}</button>
              </a>
          </div>
        </div>
        <div className="m-4">
          <img className='flex relative max-h-[70vh] xl:max-h-[83vh] w-[30vw] xl:w-[32vw] object-cover rounded-3xl' src={MainImage} alt="Main" />
          <div className="flex absolute items-center justify-center md:top-1/2 xl:top-[50vh] md:left-[46vw] xl:left-[48vw] bg-[#7A66E9] shadow-lg shadow-[#7A66E9] md:w-[150px] xl:w-[190px] md:py-2 xl:py-4 px-5 rounded-xl animate-fly">
            <p className='text-white text-center'><span className='font-extrabold md:text-xl xl:text-3xl'>{t('24')}</span><br></br> {t('total')} <span className='font-semibold md:text-sm xl:text-xl'>{t('free')}</span></p>
          </div>
          <div className="flex flex-col absolute md:top-[60vh] top-[70vh] left-[78vw] bg-white shadow-2xl md:w-[150px] xl:w-[200px] md:py-2 xl:py-4 px-4 rounded-xl animate-fly">
            <div className="flex flex-row justify-center ">
              <p className='font-black text-[#7A66E9] md:text-xl xl:text-3xl'>+300</p>
              <img className='h-full w-auto ml-2' src={Heart} alt="heart" />
            </div>
            <p className='font-bold text-center md:text-sm xl:text-base pt-1'>{t('members')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
