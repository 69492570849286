import React from 'react'
import MainImage from '../../images/blabber_main.jpg'
import Donut from '../../images/pattern_donut.png'
import { useTranslation } from 'react-i18next';


export default function Hero() {

const { t } = useTranslation('hero');

  return (
    <div className='flex flex-col h-full w-full items-center justify-start'>
      <div className='absolute z-0 top-0 right-0 w-full'>
          <img src={Donut} alt="Donut"/>
      </div>
      <div className="flex flex-col items-center justify-start w-full h-[95vh]">
        <div className="flex flex-col items-center justify-center mt-12">
        <span className='text-left text-[#9ca3af] text-[9px] py-2.5 font-medium'>{t('slogan')}</span>
            <div className="flex relative flex-col justify-center items-center w-full mb-8">
                <img className='flex justify-center max-h-[45vh] sm:max-h-[48vh] w-auto rounded-3xl' src={MainImage} alt="Main" />
                <div className="flex absolute bottom-5 items-center justify-center mt-2 bg-[#7A66E9] shadow-lg shadow-[#7A66E9] max-w-[60vw] sm:max-w-[50vw] py-4 px-5 rounded-xl animate-fly">
                    <p className='text-white text-center'><span className='font-extrabold text-2xl'>{t('24')}</span><br></br> {t('total')} <span className='font-semibold text-lg'>{t('free')}</span></p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center">
                <h1 className="text-center text-2xl sm:text-3xl px-2 font-bold mb-4">{t('hero_black_1')}<br></br> {t('hero_black_2')}<br></br> <span className='text-[#7A66E9]'>{t('hero_black_3')}</span></h1>
                <p className='text-center text-sm px-6 text-[#9ca3af]'>{t('hero_description')}</p>
                <div className="flex flex-row mt-6 mb-3">
                    <button className='px-6 py-2 bg-[#7A66E9] shadow-lg shadow-[#7A66E9] rounded-full text-white mr-4 text-sm font-semibold'><a href='#m-start'>{t('connect')}</a></button>
                    <button className='px-6 py-2 shadow-lg shadow-[#7A66E9] rounded-full text-[#7A66E9] mr-4 text-sm font-semibold'><a href='#m-about'>{t('discover')}</a></button>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
