import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Homepage from './pages/Homepage/Homepage';
import Teachers from './pages/Teachers/Teachers';
import NotFound from './pages/NorFound/NotFound';
import Navbar from './components/Navbar';
import Faq from './pages/Faq/Faq';
import Blog from './pages/Blog/Blog';
import Admin from './pages/Admin/Admin';
import Contacts from './pages/Contacts/Contacts';
import UnderConstruction from './components/UnderConstruction';
import PostDetails from './pages/Blog/PostDetails';
import MainPostDetails from './pages/Blog/MainPostDetails';


function App() {

  return (
    <div className="App flex flex-col w-full min-h-[100vh] justify-between">
      <Router>
        
        {/* <Header /> */}
        <Navbar />

          <Routes>
            <Route path="/" element={<Homepage />} />
              <Route path="/teachers" element={<Teachers />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<PostDetails />} />
              <Route path="/blog/main" element={<MainPostDetails />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/underconstruction" element={<UnderConstruction />} />
              <Route path="*" element={<NotFound />} />
          </Routes>

        </Router>
    </div>
  );
}


export default App;
