import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


export default function Partner() {

const { t } = useTranslation('partner');

  return (
    <div className='flex justify-center items-center w-auto h-auto py-4 xl:py-6 px-12 bg-[#F5F8FA] mt-10 xl:mt-24 xl:mb-8 rounded-3xl'>
        <p className='font-semibold'>{t('partner')}</p>
        <Link className='bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 ml-4 py-3 px-5 rounded-full text-white text-sm font-semibold' to='/contacts'>{t('touch')}</Link>
    </div>
  )
}
