import React from 'react';
import { Link } from 'react-router-dom';
import BlogMain from '../../images/blog_main.jpg';


export default function MainPostDetails() {

  return (
    <div className='flex flex-col lg:flex-row w-full h-full justify-center lg:justify-center items-center text-5xl font-bold mb-24 lg:mb-12'>
        <p className='lg:hidden my-4 text-4xl sm:text-3xl font-light font-quicksand'>blabber</p>
        <div className="flex flex-col xl:justify-center items-center lg:mt-24 w-[90vw] max-w-[360px] md:w-[60vw] md:max-w-[900px] h-auto p-4 sm:p-8 lg:p-8 rounded-2xl">
          <h3 className='text-2xl lg:text-3xl text-center mt-4 lg:mt-0'>The Four Strands of Language Learning: A Comprehensive Approach</h3>
          <p className='mt-4 font-light text-xs lg:text-base'>by Paul Nation</p>
          <img className='lg:w-[100%] rounded-3xl my-8 lg:mt-8' src={BlogMain} alt="Bolg main" />
          <p className='text-lg mb-4 font-medium'>Learn about the four strands of language learning developed by Paul Nation, and how they can help you achieve fluency and proficiency in a new language.</p>
          <article className='text-sm xl:text-lg mt-4 font-light'>If you're serious about learning a new language, you need to take a comprehensive approach that incorporates all four strands of language learning. Developed by language expert Paul Nation, the four strands include meaning-focused input, meaning-focused output, language-focused learning, and fluency development. <br></br><br></br>
            Each of these strands plays a critical role in language learning, and together, they provide a holistic approach that helps learners achieve fluency and proficiency in a new language.<br></br><br></br>

            Meaning-focused input involves listening and reading in the target language. This strand emphasizes the importance of understanding the meaning of the language you're learning, rather than focusing solely on grammar rules or vocabulary lists. By exposing yourself to authentic language input, you can develop your comprehension skills and build a strong foundation for future language development.<br></br><br></br>

            Meaning-focused output, on the other hand, emphasizes speaking and writing in the target language. This strand encourages learners to take what they've learned through input and apply it in meaningful ways. By producing language, learners can practice their grammar and vocabulary skills, build their confidence, and develop their ability to express themselves in the target language.<br></br><br></br>

            Language-focused learning involves studying the mechanics of the target language, including grammar, vocabulary, and pronunciation. This strand is important for developing accuracy and precision in language use. By studying the language in a focused way, learners can deepen their understanding of how the language works and become more proficient in using it.<br></br><br></br>

            Finally, fluency development focuses on helping learners become more comfortable and natural in their use of the target language. This strand emphasizes the importance of practicing and using the language in real-world contexts, such as in conversations with native speakers. By building fluency, learners can become more confident and effective communicators in the target language.<br></br><br></br>

            Incorporating all four strands of language learning into your language learning routine can help you make steady progress and achieve your language learning goals. By focusing on meaning and fluency, as well as accuracy and precision, you can develop a well-rounded set of language skills that will serve you well in any context.</article>
          <Link className="bg-[#7A66E9] text-white w-[100px] lg:w-[120px] py-2 px-4 text-center text-xs lg:text-sm rounded-xl mt-8" to="/blog">Back</Link>
        </div>
    </div>
  )
}
