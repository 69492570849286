import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import NavbarEN from './locations/English/NavbarEN.json'
import HeroEN from './locations/English/HeroEN.json'
import PartnerEN from './locations/English/PartnerEN.json'
import AboutEN from './locations/English/AboutEN.json'
import GroupsEN from './locations/English/GroupsEN.json'
import StartEN from './locations/English/StartEN.json'
import HelpEN from './locations/English/HelpEN.json'
import FaqEN from './locations/English/FaqEN.json'
import ContactsEN from './locations/English/ContactsEN.json'
import SubscribeEN from './locations/English/SubscribeEN.json'
import FooterEN from './locations/English/FooterEN.json'

import NavbarSV from './locations/Swedish/NavbarSV.json'
import HeroSV from './locations/Swedish/HeroSV.json'
import PartnerSV from './locations/Swedish/PartnerSV.json'
import AboutSV from './locations/Swedish/AboutSV.json'
import GroupsSV from './locations/Swedish/GroupsSV.json'
import StartSV from './locations/Swedish/StartSV.json'
import HelpSV from './locations/Swedish/HelpSV.json'
import FaqSV from './locations/Swedish/FaqSV.json'
import ContactsSV from './locations/Swedish/ContactsSV.json'
import SubscribeSV from './locations/Swedish/SubscribeSV.json';
import FooterSV from './locations/Swedish/FooterSV.json'


const resources = {
  en: {
    navbar: NavbarEN,
    hero: HeroEN,
    partner: PartnerEN,
    about: AboutEN,
    groups: GroupsEN,
    start: StartEN,
    help: HelpEN,
    faq: FaqEN,
    contacts: ContactsEN,
    subscribe: SubscribeEN,
    footer: FooterEN
  },

  sv: {
    navbar: NavbarSV,
    hero: HeroSV,
    partner: PartnerSV,
    about: AboutSV,
    groups: GroupsSV,
    start: StartSV,
    help: HelpSV,
    faq: FaqSV,
    contacts: ContactsSV,
    subscribe: SubscribeSV,
    footer: FooterSV
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
