import React from 'react'
// import One from '../../images/one.png'
// import Two from '../../images/two.png'
import DoneIcon from '../../images/tickcircle.png'
import { useTranslation } from 'react-i18next';


export default function About() {
const { t } = useTranslation('about');
    
  return (
    <div id='m-about' className='flex flex-col items-center justify-center w-[90vw] sm:w-[76vw] md:w-[60vw] h-auto sm:mt-12 md:mt-0'>
        <div className="flex flex-col justify-center items-start w-full px-6">
            <h2 className='text-3xl text-left mb-4'>{t('about_h')} <span className='text-[#7A66E9]'>{t('blabber_h')}</span></h2>
            <p className='text-left mb-6 text-sm text-[#5B5B5B]'>{t('about_description')}</p>
                <div className="flex flex-col items-start justify-center mb-4">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('language_club')}</h4>
                    </div>
                    <p className='text-left text-sm text-[#5B5B5B]'>{t('language_club_description')} </p>
                </div>
                <div className="flex flex-col items-start justify-center mb-4">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('own_channels')}</h4>
                    </div>
                    <p className='text-left text-sm text-[#5B5B5B]'>{t('own_channels_description')} </p>
                </div>
                <div className="flex flex-col items-start justify-center mb-2">
                    <div className="flex flex-row items-center justify-center mb-2">
                        <img src={DoneIcon} alt="Done" />
                        <h4 className='text-left font-bold ml-2'>{t('teachers_channels')}</h4>
                    </div>
                    <p className='text-left text-sm text-[#5B5B5B]'>{t('teachers_channels_description')} </p>
                </div>
        </div>
    </div>
  )
}
