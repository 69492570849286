import React from 'react'
import { Link } from 'react-router-dom'
import Funboy from '../../images/funboy.jpg'
import { useTranslation } from 'react-i18next';


export default function MobilePartner() {
const { t } = useTranslation('partner');

  return (
    <div className='flex flex-col justify-center items-center w-auto sm:w-[74vw] h-auto py-6 px-6 sm:p-10 mx-6 bg-[#F5F8FA] mt-12 mb-4 rounded-3xl'>
        <img className='rounded-xl mb-6' src={Funboy} alt="Boy" />
        <p className='font-semibold text-center sm:py-4'>{t('partner')}</p>
        <Link className='bg-[#7A66E9] shadow-lg shadow-[#7A66E9] hover:bg-[#8775EB] duration-150 ml-4 mt-4 py-3 px-8 rounded-full text-white text-sm font-semibold' to='/contacts'>{t('touch')}</Link>
    </div>
  )
}
