import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { IoReorderFourOutline, IoClose } from "react-icons/io5";
import { FaFacebook, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import Logo from '../images/logo512.png'
import { useTranslation } from 'react-i18next';


export default function Navbar() {

const [toggle, setToggle] = useState(false);

// const { i18n } = useTranslation('navbar');

  // const handleLanguageChange = (event) => {
  //   i18n.changeLanguage(event.target.value);
  // };

const { t } = useTranslation('navbar');


return (
    <nav className='fixed bottom-0 lg:top-0 left-0 z-30 w-full h-[70px] shadow-xl bg-white navbar'>
      <div className="flex items-center justify-between lg:w-full h-[70px] bg-white sm:px-6 px-7">
      {/* <img className='h-8 w-8 m-2.5 rounded-md' src={Logo} alt="Logo" /> */}
      <Link to="/" className='hidden lg:flex my-8 ml-12 text-5xl sm:text-3xl font-light font-quicksand tracking-wider'>blabber</Link>

          <div className="fixed top-0 left-0 flex pl-4 justify-start items-center z-30 lg:hidden w-full bg-white shadow-xl">
            <img className='h-8 w-8 m-2.5 rounded-md' src={Logo} alt="Logo" />
            <a href='/' className='text-black font-quicksand text-xl sm:text-4xl font-light sm:mt-0 tracking-wider'>blabber</a>
            <div className="flex lg:hidden items-center mr-4 justify-end w-full h-full">
            <a href='https://www.facebook.com/' target='_blank' rel="noreferrer" className="px-3 mt-1 text-xl text-black hover:cursor-pointer"><FaFacebook/></a>
            <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer" className="px-3 mt-1 text-xl text-black hover:cursor-pointer"><FaLinkedin/></a>
            <a href='https://instagram.com/blabber.one?igshid=ZDdkNTZiNTM=' target='_blank' rel="noreferrer" className="px-3 mt-1 text-xl text-black hover:cursor-pointer pr-6"><FaInstagramSquare/></a>
          </div>
        </div>
        <div className='hidden lg:flex items-center justify-between h-[70px] bg-white sm:px-6 px-7'>
        </div>
        <div className="hidden lg:flex items-center mr-10 justify-end w-full h-full">
          <a href='https://www.facebook.com/' target='_blank' rel="noreferrer" className="px-4 mt-1 text-xl hover:text-[#7A66E9] hover:cursor-pointer"><FaFacebook/></a>
          <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer" className="px-4 mt-1 text-xl hover:text-[#7A66E9] hover:cursor-pointer"><FaLinkedin/></a>
          <a href='https://instagram.com/blabber.one?igshid=ZDdkNTZiNTM=' target='_blank' rel="noreferrer" className="px-4 mt-1 text-xl hover:text-[#7A66E9] hover:cursor-pointer pr-6 border-r border-r-[#7A66E9]"><FaInstagramSquare/></a>
        </div>
        <div className={`md:flex flex flex-col justify-center lg:flex-row items-center lg:pb-0 mr-8 lg:mr-4 absolute top-0 left-0 h-screen lg:h-full lg:static bg-white lg:z-auto z-[-1] w-full lg:w-auto text-base font-semibold z-1 lg:pr-6 transition-all duration-500 ease-in ${toggle ? 'top-[-690px]' : 'top-[70px]'}`}>
            <Link onClick={()=>setToggle((prev) => !prev)} to="/" className='my-7 px-3 hover:text-[#7A66E9] hover:cursor-pointer duration-200'>{t('home')}</Link>
            <Link onClick={()=>setToggle((prev) => !prev)} to="/blog" className='px-3 my-7 hover:text-[#7A66E9]  hover:cursor-pointer duration-200'>{t('blog')}</Link>
            <Link onClick={()=>setToggle((prev) => !prev)} to="/faq" className='px-3 my-7 hover:text-[#7A66E9] hover:cursor-pointer duration-200'>{t('faq')}</Link>
            <Link onClick={()=>setToggle((prev) => !prev)} to="/contacts" className='px-3 my-7 hover:text-[#7A66E9] hover:cursor-pointer duration-200'>{t('contacts')}</Link>
        </div>

        {/* <select className='text-[#7A66E9] font-semibold' onChange={handleLanguageChange} value={i18n.language}>
          <option value="en">English</option>
          <option value="sv">Swedish</option>
        </select> */}
        
        <div onClick={()=>setToggle((prev) => !prev)} className='text-3xl absolute text-black border border-gray-200 hover:bg-violet-500 rounded-xl p-2 hover:text-white duration-500 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  cursor-pointer lg:hidden'>
            {toggle ? <IoClose/> : <IoReorderFourOutline/> }
        </div>
    </div>
    </nav>
  )
}
